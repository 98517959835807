import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';

type MetricProps = {
    name: string;
    value: string;
    link?: string;
};

/**
 * @param value
 */
const formatMetric = (value: string | number): string => {
    if (typeof value === 'string' || Number.isNaN(value)) {
        if ((value as string).indexOf('(') !== -1) {
            return value as string;
        }

        if ((value as string).length <= 10) {
            return value as string;
        }
    }

    const split1 = value.toString();
    let rg = /,/gm;
    const split2 = split1.replace(rg, '');
    rg = /\.[0-9]\w+/;
    const removeDecimals = split2.replace(rg, '');
    const number = removeDecimals.replace('$', '');
    const numLength = number.length;
    let percentage;

    const reduce = (num1: number, num2: number, num3: number, letter: string) => {
        if (number.length === num1) {
            percentage = number.slice(3, 5);
            return `$${number.slice(0, 3)}.${percentage}${letter}`;
        }
        if (number.length === num2) {
            percentage = number.slice(2, 4);
            return `$${number.slice(0, 2)}.${percentage}${letter}`;
        }
        if (number.length === num3) {
            percentage = number.slice(1, 3);
            return `$${number.slice(0, 1)}.${percentage}${letter}`;
        }

        return '';
    };

    if (numLength >= 7 && numLength <= 9) {
        return reduce(9, 8, 7, ' M');
    }
    if (numLength <= 12) {
        return reduce(12, 11, 10, ' B');
    }
    if (numLength <= 15) {
        return reduce(15, 14, 13, ' T');
    }

    return '';
};

const Metric = (props: MetricProps) => {
    const { name, value, link } = props;

    return (
        <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
            <div className="flex justify-start items-center flex-grow h-8 pr-2 max-w-sm">
                <div className="flex justify-start items-start flex-grow relative gap-2.5">
                    <p className="flex-grow text-sm text-left">
                        {link ? (
                            <Link prefetch={false} href={link} className="hover:text-brand">
                                {name}
                            </Link>
                        ) : (
                            name
                        )}
                    </p>
                </div>
            </div>
            <div className="flex justify-start items-center h-8">
                <div className="flex justify-start items-center flex-grow relative gap-1 text-right">
                    <p className="flex-grow text-sm text-brand">
                        {link ? (
                            <Link prefetch={false} href={link}>
                                {formatMetric(value)}
                            </Link>
                        ) : (
                            formatMetric(value)
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Metric;
