import * as React from 'react';
import { useState } from 'react';

import { cn } from '@blockworks/ui/utils';

export type NewsCategoryTab = {
    name: string;
    slug: string;
    description: string;
    id: number;
};

type NewsCategoryTabsProps = {
    className?: string;
    tabs: NewsCategoryTab[];
    onClickTab: (tab: NewsCategoryTab) => void;
};

const NewsCategoryTabs = (props: NewsCategoryTabsProps) => {
    const { className, tabs, onClickTab } = props;
    const [currentTab, setCurrentTab] = useState<NewsCategoryTab | null>(null);

    return (
        <>
            <div className={cn('uppercase py-8 lg:py-4', className)}>
                <div className="md:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand focus:border-brand sm:text-sm rounded-md"
                        onChange={e => {
                            const tab = tabs.find(foundTab => foundTab!.name === e.target.value)!;
                            setCurrentTab(tab);
                            onClickTab(tab);
                        }}
                    >
                        {tabs.map(tab => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden md:block">
                    <div className="border-b border-gray-200">
                        <nav
                            className="-mb-px absolute px-8 left-0 right-0 h-18 flex flex-row w-full overflow-x-auto no-scrollbar space-x-1 lg:space-x-6 xl:space-x-10 whitespace-nowrap text-xxs md:text-xxs lg:text-md xl:text-sm font-medium"
                            aria-label="Tabs"
                        >
                            {tabs.map(tab => (
                                <span
                                    key={tab.name}
                                    onClick={() => {
                                        setCurrentTab(tab);
                                        onClickTab(tab);
                                    }}
                                    className={cn(
                                        currentTab?.id === tab.id
                                            ? 'border-brand text-brand'
                                            : 'border-transparent hover:text-gray-700 hover:border-gray-300',
                                        'pt-4 pb-6 px-1 border-b-2 cursor-pointer',
                                    )}
                                    aria-current={currentTab?.id === tab.id ? 'page' : undefined}
                                >
                                    {tab.name}
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsCategoryTabs;
