import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import NewsletterSubscribeInput from '@/components/pages/frontpage/components/newsletter-subscribe-input';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type NewsletterProps = {
    className?: string;
    title: string;
    subTitle: string;
    description?: string;
    inputPlaceholder: string;
    buttonText: string;
    onButtonClick: typeof subscribeToNewsletter;
};

const Newsletter = (props: NewsletterProps) => {
    const { title, subTitle, description, inputPlaceholder, buttonText, onButtonClick, className } = props;

    return (
        <div className={cn('gap-4 p-10', className)}>
            <div className="flex flex-col justify-start items-start self-stretch gap-2">
                <div className="flex justify-start items-center self-stretch relative gap-1">
                    <p className="flex-grow text-xs uppercase text-brand">
                        <Link href="/newsletter" prefetch={false}>
                            {title}
                        </Link>
                    </p>
                </div>
                <div className="flex justify-start items-start self-stretch relative gap-2.5">
                    <p className="flex-grow text-[22px] text-left">
                        <Link href="/newsletter/daily" prefetch={false}>
                            {subTitle}
                        </Link>
                    </p>
                </div>
                <div className="flex justify-start items-start self-stretch relative gap-2.5">
                    <p className="flex-grow text-sm text-light-gray cursor-default">{description}</p>
                </div>
            </div>
            <NewsletterSubscribeInput
                className="mt-2"
                inputPlaceholder={inputPlaceholder}
                buttonText={buttonText}
                source="homepage-right-rail"
                onButtonClick={onButtonClick}
            />
        </div>
    );
};

export default Newsletter;
