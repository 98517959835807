import * as React from 'react';
import Image from 'next/image';

import { PostType } from '@blockworks/platform/api/strapi';
import { RelativeTime } from '@blockworks/ui/date-time';
import { cn } from '@blockworks/ui/utils';

import EditorialConfig from '@/editorial-config';

type ReasearchTeaserProps = {
    headerTitle: string;
    article: PostType;
    className?: string;
};

const ReasearchTeaser = (props: ReasearchTeaserProps) => {
    const { headerTitle, article, className } = props;

    if (!article) return null;

    const { slug, title, publishedAtTimestamp, primaryAuthor, featuredImage, excerpt } = article;

    return (
        <div className={cn('flex flex-col justify-start items-start gap-3', className)}>
            <div className="flex flex-col justify-start self-stretch gap-2">
                {featuredImage?.url && (
                    <div className="min-h-[100px]">
                        <a
                            href={`${EditorialConfig.blockworksResearchUrl}/research/${slug}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                src={featuredImage.url}
                                className="w-full h-auto"
                                alt={featuredImage.name || ''}
                                width={384}
                                height={0}
                                sizes="(min-width: 60em) 300px, (min-width: 28em) 150px"
                                loading="eager"
                                quality={20}
                                blurDataURL={
                                    featuredImage.blurhash ||
                                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADA...'
                                }
                                placeholder="blur"
                            />
                        </a>
                    </div>
                )}
                <div className="flex justify-start items-center self-stretch gap-1">
                    <p className="flex-grow text-xs uppercase text-brand">{headerTitle}</p>
                </div>
                <div className="flex justify-start items-start self-stretch gap-2.5">
                    <a
                        href={`${EditorialConfig.blockworksResearchUrl}/research/${slug}`}
                        target="_blank"
                        className="flex-grow text-[22px] font-headline hover:text-brand"
                        rel="noreferrer"
                    >
                        {title}
                    </a>
                </div>
                <div className="flex justify-start items-start self-stretch gap-2.5">
                    <p className="flex-grow text-base text-left text-[#6a6a6a] line-clamp-6">{excerpt}</p>
                </div>
                <div className="flex justify-start items-start self-stretch gap-1">
                    <div className="flex justify-start items-start relative">
                        <p className="text-xs uppercase text-light-gray">
                            by{' '}
                            <a
                                href={`https://app.blockworksresearch.com/team/${primaryAuthor?.slug || ''}`}
                                className="text-brand hover:text-dark"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {primaryAuthor?.name || 'Research Team'}
                            </a>
                        </p>
                    </div>
                    {publishedAtTimestamp && publishedAtTimestamp !== 0 && (
                        <div className="flex justify-start items-start gap-1">
                            <p className="text-xs uppercase text-light-gray">/</p>
                            <p className="text-xs uppercase text-light-gray">
                                <RelativeTime date={publishedAtTimestamp} />
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReasearchTeaser;
